// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-for-public-js": () => import("./../../../src/pages/for-public.js" /* webpackChunkName: "component---src-pages-for-public-js" */),
  "component---src-pages-for-students-js": () => import("./../../../src/pages/for-students.js" /* webpackChunkName: "component---src-pages-for-students-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laboratory-js": () => import("./../../../src/pages/laboratory.js" /* webpackChunkName: "component---src-pages-laboratory-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-algal-js": () => import("./../../../src/pages/research-algal.js" /* webpackChunkName: "component---src-pages-research-algal-js" */),
  "component---src-pages-research-fires-js": () => import("./../../../src/pages/research-fires.js" /* webpackChunkName: "component---src-pages-research-fires-js" */),
  "component---src-pages-research-historic-js": () => import("./../../../src/pages/research-historic.js" /* webpackChunkName: "component---src-pages-research-historic-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-research-landscape-js": () => import("./../../../src/pages/research-landscape.js" /* webpackChunkName: "component---src-pages-research-landscape-js" */),
  "component---src-pages-research-pollen-sedimentation-js": () => import("./../../../src/pages/research-pollen-sedimentation.js" /* webpackChunkName: "component---src-pages-research-pollen-sedimentation-js" */),
  "component---src-pages-research-wetlands-js": () => import("./../../../src/pages/research-wetlands.js" /* webpackChunkName: "component---src-pages-research-wetlands-js" */)
}

